import React from "react"

import { Link } from "gatsby"

import Layout from '../containers/layout'

import Container from '../components/container'
import SearchBar from '../components/search-bar'

// styles
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Container>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <p style={paragraphStyles}>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
        </p>
        <p>
          Try searching for the page:
          <SearchBar></SearchBar>
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
